html, body {
    margin:0;
    border:0;
    background-color:#dffaff;
}

button, select {
    color: black; /*iOS seems to use some darkish-blue color as the default now*/
}

:root {
    --defaultFontSize: 3.2vw;

    --riverRatingSize: 2vw;
    --riverNameWidth: 24%;
    --riverSectionWidth: 30%;
    --riverSkillWidth: 8%;
    --riverClassWidth: 11%;
    --displayRatingSpan: none;
}

@media screen and (min-width: 550px) {
    :root {
        /*Larger screen mode for .riverbutton*/
        --riverNameWidth: 19%;
        --riverSectionWidth: 29%;
        --riverSkillWidth: 5%;
        --riverClassWidth: 10%;
        --displayRatingSpan: inline-block;
    }
}


/*Font size breakpoints.*/
@media screen and (max-width: 370px) {
    :root {
        --defaultFontSize: 3.48vw; /*Any bigger than this, and we get quite a bit of text overlapping.*/
    }
}


@media screen and (max-width:309px) {
    /*Smart Watch Mode*/
    :root {
        --defaultFontSize: 5.3vw;

        /*.riverbutton*/
        --riverRatingSize: 3.5vw;
        --riverNameWidth: 40%;
        --riverSectionWidth: 60%;
        --riverSkillWidth: 8%;
        --riverClassWidth: 20%;
        --displayRatingSpan: inline-block;
    }
}

.riverspan:nth-child(5) {
    transform: scaleY(1.25) /*Make the stars larger without using more horizontal space.*/
}
@media screen and (min-width: 500px) {
    :root {
        --defaultFontSize: 2.8vw;
        --riverRatingSize: var(--defaultFontSize);
    }

    .riverspan:nth-child(5) {
        transform: scaleY(1); /*Remove the vertical scaling of star images.*/
    }
}

@media screen and (min-width: 550px) {
    :root {
        --defaultFontSize: 2.4vw; /*Make more space for the 4th span.*/

    }
}

@media screen and (min-width: 800px) {
    :root {
        --defaultFontSize: 2vw;
    }
}

@media screen and (min-width: 1000px) {
    :root {
        --defaultFontSize: 1.7vw;
    }
}

@media screen and (min-width: 1250px) {
    :root {
        --defaultFontSize: 1.4vw;
    }
}

@media screen and (min-width: 1500px) {
    :root {
        --defaultFontSize: 1.2vw;
    }
}

@media screen and (min-width: 1750px) {
    :root {
        --defaultFontSize: 21px;
    }
}


/*Chart CSS*/
.graph {
    margin: auto;
    border: 1px solid black;
    width: auto;
    height: 80vw; /*Try to stretch vertically on taller screens (but less wide)*/
    max-width: 95vw;
    max-height: 75vh;
}
/*★☆⬆⬇*/
/*Make sure I have the two star images being the same size, and arrows that look how I want, in a font.*/
/*
https://fonts.googleapis.com/css?family=Noto Sans HK&text=★☆⬆⬇
*/
@font-face {
  font-family: 'Noto Sans HK';
  font-style: normal;
  font-weight: 400;
  src: url(data:font/woff2;charset=utf-8;base64,d09GMk9UVE8AAAS8AAwAAAAAB3AAAARyAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAADYYfBmAOCABsATYCJAMMBAYFg3AHIBckGAwbngbIxIfezXqTMKFXUuXYs0pqhtiq0FV6KojVwtF0XWqyvyciMsAg//+949rat3AsDSzl9vu3sTbOZZ0vFgc0xrY+YBhg4JSWbTW1MLxcFk6VDIFOB0KIcZOqqWf6+gc613ecEYoM9voSUwdOryBDFWR4WK6qIEcI2EcF9jHx2EEdb5XF3O7crZWAIe6W60vpqFxdCeRfk2vpJQtLrHeF3ABso1xPSi2DnpCLL9+1f+i6J1aq8W2JWCSqKXarzdkOmuu1ecryoOoPKc1RTYsvsFi2bNli9o1qzAG1z9JiVq5zoRIbUHyKlvAFQ33qbI+ihhWPqkZ6Q8ra/oBZVNxKQulcr7iusfLgY6wNhvq1mLbttsfrWBXrj/h92sD0Bzyh/lAiFgAIFQjtJCIEgIAAFAIhJZUVZERurIBSCQjflFeQ0R6l4gtEsRxUAMFZGCdVZAUJc/mx1B/fn/9Y+R+PpboeMz74B5dNqXvTO7P3Ssn6zKUm/nayg6ZKJX535hyT8UPmcrPP67NWvymoZm9nOmjWmvqDWKaD/zfFG9IF5K60VUyfkxw0jfO/9Vxg39IjEhf4t5T/fURid3AfF/n3Y0PsHhZk9/Dg2AgX2fe0OHnWnT237J8pvzNtN04kR1IvmQ5IHPxbyv+WhvazO/lFnPJvpg6xu1iY3cXDU5Ocsm+o0cruZBcNzVDjBP9bMnZltvaYjBO/H6asS3qWea7/lY5K17U+xz2UeSVj1x8T9JjeeRZvrXmGL7i35tiQ7W2u/M2VetuqYyP3/vcMW8Bb/3M+TYtXp5sI+5p9KTJH8gITW5m5EreTV7KVIxL/KXkq/ylzKi12y0+nPmPPm/jK5JX8y8yVfOWQxH7KnMp+Sipv7svhD5TMg8PSa4VvGm6Zvk6Wbzl+nVw0Ipeld574v4mPSWy6Zsc9hfcY7vlElu/5xFdAFD8kE6BoEIaE8wCsL6XIJbCT1X2CgSJyLlM6L3u8Xg+WNdI2vRjPHwV0R4lyWTnRm4hQLXxmvAqkT8YmxlcbxBRV0EBIKeStgg7NFOkQgdAotenzeaCRtkkq9lHm3exGRQSyNjT3cEGOAI0SIgRoM3jbmVVHPiUACdFhvrRk0X8Fovjj6uSH5L07TF1mDv/RENeXhiAYnFy/NfflUyzLzMl+ZIifUxMtI+KPCKIRDbjCWdH1N2SBAoTPZ9GdwICfNLcigpNJqUhAMRFEItbg0V6dLKII4jyRhHo43y2GE0AUGjTEsQAWWDCAABKIIQ4NHDMG3u2FGSoSiMACL9ZgAzZBhQYVZ8KHfsh2Yj06cAZC6wfRCx8SOAchJPZFKvIV2GGGNfYtRFRDYBZtJs3mMcNGNJf5ffChB6ENxWGEsBWhvZELw91wwYX5cBxv+h544YUHC6/sVwuxHKvgxQqsntXsaVIICuGms5dbYYMT7a28C83z+csRhAr/tRI1P1fYEofM8Mlgvv9MHyxo8XrxOxiYPwA7olxSCvJvTv6CuHl39kIiUIFaYCEWog52) format('woff2'),
  url("resources/stars.woff") format("woff"); /*I could inline woff instead, but Safari 9.3 can go slower.*/
}

/*position: relative is on container (riverspan:nth-child(5)).*/
/*Some stuff with emptyStars is set in River.js*/
/*fullStars width set in River.js*/
	.emptyStars, .fullStars {
		overflow:hidden;
		padding:0;
		margin:0;
		display:inline-block;
        font-family: 'Noto Sans HK', Arial, Helvetica, sans-serif;
        font-size: var(--riverRatingSize);
	}

	.fullStars {
		color:yellow;
		position: absolute;
		left:0;
        top:0;
	}

.notificationsContainer {
	text-align: center;
	margin-bottom: 0.5em; /*Add some space between this and the graph.*/
	font-size: 16px;
}
.minimum {
	font-weight: bold;
	width: 90px;
}
.maximum {
	font-weight: bold;
	width: 110px;
}

#calculateCoordinates {
	background-color: lightgreen;
	border: none;
	border-radius: 5px;
	padding: 4px;
}

.riverWriteup img {
	max-width: 98vw; /*Prevent images from overflowing the screen. */
}

.emptyStars {
	position:static;
	top:0px;
	left:0px;
}

.oldDataWarning, #topOldDataWarning {
	background-color:yellow;
	text-align:center;
	line-height:2em;
	color:black; /*Make sure the text is not white in dark mode*/
}

.searchcontain {
    margin-bottom:18px;
    margin-left:4px;
}

#searchimg {
    display:inline-block;
    max-width: 43px;
    width:auto;
    height:auto;
    vertical-align:middle;
}

/*Keep the search bar in one line on smaller devices. */
@media (max-width: 400px){
    .searchcontain {
        text-align: center;
    }

    #searchimg {
        display: none;
    }
}


#advancedsearch, #addAllToFavorites {
    display: inline-block;
    vertical-align: middle;
    height: 36px;
}

.tempButton, .flowButton, .precipButton {
	font-size: 16px;
    border: 0;
    color: white;
	padding: 8px;
	min-width: 20vw;
}

@media screen and (min-width: 600px) {
	.tempButton, .flowButton, .precipButton {
		font-size: calc(var(--defaultFontSize) * 1.2)
	}
}

.flowButton {
    background-image: linear-gradient(to right, #00AAFF , #0000FF);
}

.tempButton {
    background-image: linear-gradient(to right, red , blue);
}

.precipButton {
    background-color: #0077BB;
}

#advancedSearchWarning {
    text-align: center;
    background-color:lightpink;
    margin:0;
    margin-bottom:8px;
    padding:6px;
    display:none;
}

#searchbox {
    display:inline-block;
    vertical-align:middle;
    padding:0;
    height: 36px;
    width: 20%;
	padding-left:5px;/*Prevent the text from being on the left edge.*/
	font-size: 16px; /*iOS auto zooms with font-size less than 16px.*/
}

#advanced-search-modal > .modal-content > * {
    font-size: 16px; /*iOS auto zooms with font-size less than 16px.*/
}
#advanced-search-modal > .modal-content > #locationSearchPortion > * {
    font-size: 16px; /*iOS auto zooms with font-size less than 16px.*/
}
button {
    font-size: 16px; /*iOS auto zooms with font-size less than 16px.*/
}

#advanced-search-modal-close {
    font-size: 36px !important; /*Set back to the default (the selector above would override)*/
}

#searchlink {
    word-break: break-all; /*Allow breaking the advanced search link so it doesn't widen the page.*/
}

#searchbox::placeholder {
    /*Make the text show up a little better.*/
    font-weight: bold;
}

#legend {
	display: block;
	z-index: 10;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	color: black;
	width: 100%;
	font-size: calc(var(--defaultFontSize) * 1.15);
	line-height: calc(var(--defaultFontSize) * 1.55);
	font-weight: bold;
}

#lowflow {
	width: 18.5%;
}

#highflow {
    width: 19.5%;
    margin-left: -1%;
}

#midflow {
	width: 20%;
	text-align: center;
	padding-bottom: calc(var(--defaultFontSize)*0.25); /*Shove the Stripes = Has Dam further down.*/
}

#toolow, #toohigh {
	width: 20%;
}

#toohigh {
	/*Was having issues with this overflowing*/
	position: absolute;
	right:2px;
}

#toolow {
	padding-left: 2px; /*Prevent the text from touching the side.*/
}

#toohigh, #highflow {
	text-align: right;
}

#legend > span {
	display: inline-block;
	min-height: 60%;
}

#legend:after {
	content:"Stripes = Has Dam";
	display:block;
	text-align: center;
	line-height: calc(var(--defaultFontSize) * 1.3);
	font-size: calc(var(--defaultFontSize) * 0.9);
	background-image: repeating-linear-gradient(150deg,transparent, transparent, rgba(0,0,0,.1),transparent, transparent, transparent 16%);
	height:40%;
}

/*riverspans are the spans that actually hold the information - there are 6 riverspans for each river.
Those include name,section,skill,quality, and flow info*/

.riverspan {
    display:inline-block;
    margin:0px;
    /*overflow:scroll;*/
    /*Scrollbars showing when not needed in Safari desktop. Need to debug*/
}

.riverspan:nth-child(1) {
    width: var(--riverNameWidth);
    min-width: var(--riverNameWidth);
    max-width: var(--riverNameWidth);
}
.riverspan:nth-child(2) {
    width: var(--riverSectionWidth);
    min-width: var(--riverSectionWidth);
    max-width: var(--riverSectionWidth);
}

.skillspan {
    width: var(--riverSkillWidth);
    min-width: var(--riverSkillWidth);
    max-width: var(--riverSkillWidth);
}

#topbar .skillspan {
    margin-left: -1%;
    margin-right: 1%; /*Add some additional space between the header for skill and the header for class*/
}

.classspan {
    width: var(--riverClassWidth);
    min-width: var(--riverClassWidth);
    max-width: var(--riverClassWidth);
}

/*Child #5 acts as a tooltip too. That declaration is a around 40 lines below (May change. Do a search for nth-child(5))*/
.riverspan:nth-child(5) {
	position: relative;
    min-width: calc(var(--riverRatingSize) * 5); /*There are 5 stars - and each star is as wide as the font size in pixels.*/
    display: var(--displayRatingSpan);
}

.riverspan:nth-child(6) {
    margin-left: 1%;
    font-family: BlinkMacSystemFont, "Noto Sans HK", Arial, Helvetica, sans-serif; /*Noto Sans HK contains the arrows that I want. On Apple Macs, use the system font.*/
}

@media screen and (min-width: 550px) {
	#topbar > .skillspan {
		/*Let "Skill" use up however much space it needs*/
		width: auto !important;
		min-width: none !important;
		max-width: none !important;
	}
}

@media screen and (max-width:309px) {
    /*Smart watch mode.*/
    .riverspan:nth-child(6) {
        margin-left: 2%;
    }
    .riverbutton {
        flex-wrap: wrap;
    }
    h1 {
        font-size: 18vw; /*Prevent the River Information text from going off the screen and making the device zoom out.*/
    }

	/*Stop disastorous overflow in the topbar.*/
	#topbar > .classspan {
		/*Make "Class" use up only the space it needs.*/
		width: auto !important;
		min-width: none !important;
		max-width: none !important;
	}
	#topbar > .skillspan {
		/*Let "Skill" use up however much space it needs*/
		width: auto !important;
		min-width: none !important;
		max-width: none !important;
	}
}
/*End of riverspan style*/


/*tooltips. Used to explain skill levels.*/
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted;
}

.tooltip .tooltiptext {
  visibility: hidden;
  white-space: nowrap;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 4px;
  position: absolute;
  z-index: 3;
  top: -5px;
  left: 155%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}





/*riverbutton is the element that holds all the riverspans*/
.riverbutton {
    width:100%;
    border:0;
    margin:0;
    transition: 0.2s;
    text-align:left;
    display: -webkit-box; /*iOS 9 and 10 workaround due to lack of flexbox support for buttons.*/
	display:flex;
	align-items:center;
}

.riverbutton:nth-child(even) {
    background-color: #f4f4f4;
}
.riverbutton:nth-child(odd) {
    background-color: #f7f7f7;
}
.riverbutton:hover {
	filter: saturate(1.5) brightness(0.9);
}

.riverbuttonDam {
	background-image: repeating-linear-gradient(150deg,transparent, transparent, rgba(0,0,0,.1),transparent, transparent, transparent 16%);
}


.riverbuttonGauge:nth-child(even) {
	background-color: #f0bdf9 !important;
}
.riverbuttonGauge:nth-child(odd) {
	background-color: #f5bdff !important;
}

.riverbutton:focus {
    outline:0;
}


/*Controlling font size smoothly based on screen size*/
.riverbutton {
    font-size: var(--defaultFontSize);
	min-height: 32px; /*Make buttons easier to click on mobile, and more uniformly sized.*/
}

@media screen and (max-width: 370px) {
    .riverbutton {
		padding-left: 4px; /*Leave a little more space for other stuff than the standard 8px.*/
		padding-right: 0; /*Give a bit more space. Rarely was this padding noticed at all.*/
    }
}

@media screen and (max-width: 499px) {
    #topbar > .skillspan {
        /*Let "Skill" use up however much space it needs*/
        width: auto !important;
        min-width: none !important;
        max-width: none !important;
    }
}
/*End of riverbutton style*/

/*topbar is the header element for all the rivers. You can click it to order alphabetically, by quality, skill, ext.*/
#topbar {
    background-color:#77ff77;
    width:100%;
    padding:10px;
    border:0;
}

#topbar:hover {
    background-color:#77ff77;
}
/*End of topbar style*/


/*Utilities Stuff - To avoid inline CSS*/
.center {
    text-align:center;
}

.gaugesBelow {
	text-align: center !important;
	background-color: #e06af4 !important; /*A lightish purple.*/
}

.mapButton {
    width: 50vw;
    padding: 8px;
    margin: 1vh;
    margin-left: 25vw;
    border-radius: 2000px; /*Fully round corners. */
    background-color: #efefef;
}

.riverMap {
    height: 80vh;
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%); /*Scale for margin. */
  /* Style the bubble. */
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 50vh;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor, .tooltip-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

.tooltip-bubble-anchor {
  bottom: 2px;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

.mapPopupCloseButton {
    float: right;
}

.popup-bubble .graph {
    height: 45vh;
    max-width: 60vw; /*80vw of elem, 0.8 transform, and margin in bubble. */
}

/*TODO: in iPad landscape, status bar intersects with the content. */
/*We don't want to force rectangular though - we just want to avoid the status bar. */
@media (orientation: portrait) {
  #legend {
    top: env(safe-area-inset-top);
  }
}


@media (prefers-color-scheme: dark) {
    html, body {
        background-color: #090920;
        color: #dddddd;
    }
    .riverbutton:nth-child(even) {
        background-color: #222222;
    }
    .riverbutton:nth-child(odd) {
        background-color: #252525;
    }
	.riverbutton:hover {
		filter: saturate(1.05) brightness(1.3);
	}

    .emptyStars {
        opacity: 0.35; /*Greatly dim outline in dark mode*/
    }

	.riverbuttonDam {
		background-image: repeating-linear-gradient(150deg,transparent, transparent, rgba(255,255,255,.2),transparent, transparent, transparent 16%);
	}

	#legend {
		color: white;
	}

    /*Stripes*/
	#legend:after {
		background-image: repeating-linear-gradient(150deg,transparent, transparent, rgba(255,255,255,.2),transparent, transparent, transparent 16%);
	}

	.gaugesBelow {
		background-color: darkmagenta !important;
	}

	select, input {
		background-color: #1e2021;
		border: 1px solid #585858;
	}

	input {
		padding-left: 4px;
	}

	select, input, input::placeholder {
		color: #e8e6e3;
	}

	.riverbuttonGauge:nth-child(even) {
		background-color: #380241 !important;
	}
	.riverbuttonGauge:nth-child(odd){
		background-color: #3c0545 !important;
	}

	#advancedSearchWarning {
        background-color:darkblue;
    }

    .riverbutton {
        color:#ffffff;
    }

    #topbar {
        color:black;
    }

    #searchbox {
        background-color:#545454;
        border: none;
        color: #FAFAFA;
    }

    #searchbox::placeholder {
        color: #FAFAFA;
    }

}
